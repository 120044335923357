export const environment = {
  "B2CClientId": "f4506a3e-2898-4104-9d99-288f9226277b",
  "apiBaseUrl": "@API-BASE-URL@",
  "apiBaseUrlAdd": "@API-BASE-URL-ADD@",
  "awsBaseUrl": "https://restoreops-api-sbx.duke-energy.app/restoreops",
  "azure_client_id": "f4506a3e-2898-4104-9d99-288f9226277b",
  "azure_redirect_uri": "https://restoreops-sbx.duke-energy.app/welcome",
  "azure_tenant": "56f1d127-82a7-4fb4-80fd-84ce35a5abdf",
  "cddaoauthkey": "@CD-DA-OAUTHKEY@",
  "cddaoauthsecret": "@CD-DA-OAUTHSECRET@",
  "cdfaceConfig": "@CD-DA-FACE-DOMAIN@",
  "envName": "sbx",
  "msalConfig": {
    "scopes": [
      "https://DukeEnergyTestB2C.onmicrosoft.com/dab2capi/user_impersonation"
    ]
  },
  "production": "false",
  "tenantPolicyConfig": {
    "B2CEndPoint": "https://dukeenergytestb2c.b2clogin.com/dukeenergytestb2c.onmicrosoft.com/",
    "employeeSignInPolicy": "B2C_1_DA_Employee_Sign_Up_Sign_In",
    "forgotPasswordPolicy": "B2C_1_Password_Reset_DA",
    "logOutRedirectUri": "https://restoreops-dev.duke-energy.app/welcome",
    "redirectUri": "https://restoreops-dev.duke-energy.app/welcome",
    "signInPolicy": "B2C_1_SignIn_DA",
    "signUpPolicy": "B2C_1_SignUp_DA"
  },
  "vsac_url": "https://outage-maps-internal-web-dev.appc-dlbcorp-eaqu1qa.duke-energy.com"
}
